import React from 'react'
import Hdr from '../_common/hdr'
import Ftr from '../_common/ftr'
import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import{Container, Image, Button, iframe} from 'react-bootstrap'
import {Row, Col} from 'react-bootstrap'
import { navigate } from "@reach/router"
import './careers.css'

class Careers_Home extends React.PureComponent
{
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render()
    {
        return (
            <div style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                <Hdr/>
                <h1><div className="headerText" style={{fontWeight: 400, marginTop: 40}}>Careers at i24</div></h1>
                <h2><div className="paraSubtext" style={{fontWeight: 300, textAlign: 'center'}}>Find your opportunity here</div></h2>
                    <div className="floatBackground">  
                        <p className="paraHeader" style={{marginTop: 20}}>Open Positions</p>
                        <p className="paraSubtext" style={{marginTop: 20}}>Marketing</p>
                            <Col>
                                <p className="paraText"> Content Manager</p>
                                <p className="paraText"> Product Marketing Associate</p>
                                <p className="paraText"> PR & Communications Associate</p>
                            </Col>
                        <p className="paraSubtext" style={{marginTop: 20}}>Engineering</p>
                            <Col>
                                <p className="paraText"> Software Engineer </p>
                                <p className="paraText"> Data Analyst </p>
                                <p className="paraText"> Product Designer </p>
                                <p className="paraText"> User Researcher </p>
                            </Col>
                        <p className="paraSubtext" style={{marginTop: 20}}>Operations</p>
                            <Col>
                                <p className="paraText"> Head of Talent </p>
                                <p className="paraText"> Junior Talent Manager </p>
                            </Col>
                        <p className="paraSubtext" style={{marginTop: 20}}>Recent Graduate Roles</p>
                            <Col>
                                <p className="paraText"> Graduate Trainee Position (x4) </p>
                            </Col>
                        <p className="paraEndtext" style={{marginTop: 40, marginLeft: "5%", marginRight: "5%"}}>If you would like to apply to any of the above positions, please send an email to <a href="mailto:hello@i24app.com">hello@i24app.com</a> with the job role in the subject line.</p>
                    </div>
                <Ftr/>
            </div>
            
        )
    }

    /**
     */
    componentDidMount()
    {
    }
}   // class Eye_Home

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)(Careers_Home)