/**
 * @copyright Elmelo Ltd.
 */

import React, { useEffect } from 'react'
import Hdr from '../_common/hdr'
import Ftr from '../_common/ftr'
import './css/eye.css'
import './css/business.css'
import '../download/download.css'
import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import{Container, Image, Button,iframe} from 'react-bootstrap'
import { navigate } from "@reach/router"
import ScrollAnimation from 'react-animate-on-scroll';
import Aos from "aos";
import "aos/dist/aos.css";
/**
 */
const Home_Home = () => {

    useEffect(() => {
        Aos.init({duration: 1000});
    }, [])
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
        return (

            <div>
                <Hdr/>

                <div className="eyeMain">
                    <h1><div className="headerText" style={{fontWeight: 650, color: '#fff',fontSize:68}}>Send invoices on the move</div></h1>
                    <h2><div className="headerSmallText" style={{color: 'gray', fontWeight: 600,fontSize:50}}>Get paid in seconds.</div></h2>
                    <Image className="mainImage" alt="Invoice generator creation screen"  src={require('../home/images/mainScreen.png')}/>
                        {/*<div className="headerSmallText" style={{color: '#347CF6'}}>Not for accountants</div>*/}
                        {/*    <div className="embed-responsive embed-responsive-16by9 videoLink">*/}
                        {/*        <iframe src="//www.youtube.com/embed/BaJtH0QNrnY"*/}
                        {/*                title="YouTube video player"*/}
                        {/*                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                        {/*                allowFullScreen></iframe>*/}
                        {/*    </div>*/}

                    {/*<div className="bulletPoints">*/}
                    {/*    <div className="bulletColumn">*/}
                    {/*        <div className="bulletPoints">*/}
                    {/*            <Image className="bulletImage" src={require('../assets/web/explore.png')}/>*/}
                    {/*            <div className="infoText">Explore</div>*/}
                    {/*        </div>*/}
                    {/*        <div className="bulletPoints">*/}
                    {/*            <Image className="bulletImage" src={require('../assets/web/chat.png')}/>*/}
                    {/*            <div className="infoText">Chat</div>*/}
                    {/*        </div>*/}
                    {/*        <div className="bulletPoints">*/}
                    {/*            <Image className="bulletImage" src={require('../assets/web/book.png')}/>*/}
                    {/*            <div className="infoText">Book</div>*/}
                    {/*        </div>*/}
                    {/*        <div className="bulletPoints">*/}
                    {/*            <Image className="bulletImage" src={require('../assets/web/pay.png')}/>*/}
                    {/*            <div className="infoText">Pay</div>*/}
                    {/*        </div>*/}
                    {/*        <div className="bulletPoints">*/}
                    {/*            <Image className="bulletImage" src={require('../assets/web/recommended.png')}/>*/}
                    {/*            <div className="infoText">Recommended</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <Image className="bottomImage" src={require('../assets/imageIcon/testing.png')}/>*/}
                    {/*    <div className="bulletColumn">*/}
                    {/*        <div className="bulletPoints">*/}
                    {/*            <Image className="bulletImage" src={require('../assets/web/generateOrders.png')}/>*/}
                    {/*            <div className="infoText">Generate Orders </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="bulletPoints">*/}
                    {/*            <Image className="bulletImage" src={require('../assets/web/chat-1.png')}/>*/}
                    {/*            <div className="infoText">Chat with Buddies</div>*/}
                    {/*        </div>*/}
                    {/*        <div className="bulletPoints">*/}
                    {/*            <Image className="bulletImage" src={require('../assets/web/manage.png')}/>*/}
                    {/*            <div className="infoText">Manage</div>*/}
                    {/*        </div>*/}
                    {/*        <div className="bulletPoints">*/}
                    {/*            <Image className="bulletImage" src={require('../assets/web/increaseRevenue.png')}/>*/}
                    {/*            <div className="infoText">Increase Revenue</div>*/}
                    {/*        </div>*/}
                    {/*        <div className="bulletPoints">*/}
                    {/*            <Image className="bulletImage" src={require('../assets/web/buildReputation.png')}/>*/}
                    {/*            <div className="infoText">Build Reputation</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<Container style={{display:"flex",alignItems:"center"}}>*/}
                    {/*    <div className="eyeContainer">*/}
                    {/*        <div className="eyeTop">*/}
                    {/*            <Image className="hslogo" src={require('./images/hslogo.png')}/>*/}
                    {/*            <div className="eyelittleText" style={{marginVertical:8}}>*/}
                    {/*                Join HiStreet to access your favourite foods and services in one location !*/}
                    {/*            </div>*/}
                    {/*            <div style={{display:"flex",flexDirection:"row"}}>*/}
                    {/*                <Button className="eyeButton" >*/}
                    {/*                    <a href="https://bit.ly/histreet-android">*/}
                    {/*                        <div>*/}
                    {/*                            <Image className="icons" src={require('./images/playstore.png')}/>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="btnText">Play Store</div>*/}
                    {/*                    </a>*/}
                    {/*                </Button>*/}
                    {/*                <Button className="eyeButton">*/}
                    {/*                    <a href="https://bit.ly/histreet-ios">*/}
                    {/*                        <div>*/}
                    {/*                            <Image className="icons" src={require('./images/appStore.png')}/>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="btnText">App Store</div>*/}
                    {/*                    </a>*/}
                    {/*                </Button>*/}
                    {/*            </div>*/}
                    {/*            */}
                    {/*            <div className="busBtn" onClick={()=>navigate('business') }>*/}
                    {/*                For Business Owners  >>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div style={{display: "flex", flexDirection: "column",flex:2,}}>*/}
                    {/*            <Image className="imagePlace" src={require('./images/hsapp.png')}/>*/}
                    {/*            /!*<div>*!/*/}
                    {/*            /!*ruuuhuhuhuh*!/*/}
                    {/*            /!*</div>*!/*/}
                    {/*        </div>*/}


                    {/*        /!*<h1>Eye_Home ...</h1>*!/*/}
                    {/*    </div>*/}
                    {/*</Container>*/}
                    {/*<Hdr/>*/}
                    {/*<h1>Eye_Home ...</h1>*/}
                </div>

                <div className="eyeMainBlack">
                    <div data-aos-once="true" className="downloadHead" style={{padding: 80, marginTop: -100, marginBottom: -100}}>
                        <div data-aos="fade-up" data-aos-delay="200" data-aos-once="true" data-aos-easing="ease-out-cubic" style={{fontSize: 60, fontWeight: 600, color: '#fff', marginBottom: 50}}>
                            Lighter, faster
                            <p style={{fontSize: 30, fontWeight: 400, color: 'gray', width: '80%'}}>Manage and track invoices and estimates, keep on top of your expenses, track business performance. </p>
                        </div>
                        <Image alt="Invoice generator dual screens" data-aos-delay="200" data-aos="fade-left" data-aos-easing="ease-out-cubic" data-aos-once="true" className="gapHome" src={require('../home/images/floatingPhones.png')}/>
                    </div>
                </div>


                {/* <div className="eyeMainGray">

                    <div data-aos="fade" data-aos-delay="200" data-aos-once="true" data-aos-easing="ease-out-cubic" style={{fontSize: 50, fontWeight: 600, color: '#fff', padding: 80}}>
                            Loved by freelancers and sole traders
                    </div>
                    <OwlCarousel className='owl-theme testimonial__carousel-wrapper' loop margin={10} nav>
                        <div class="item">
                            <div role="listitem" class="testimonial__card w-dyn-item">
                                <div class="testimonial__text">"HiStreet is quite simply the best. Tailored to freelancers and sole traders, super easy to use, excellent customer service team. No more bells and whistles than you need, making sending invoices and getting paid amazingly straightforward.’’</div>
                            </div>
                        </div>
                        <div class="item">
                            <div role="listitem" class="testimonial__card w-dyn-item">
                                <div class="testimonial__text">"We often risked losing deals by requiring annual upfront payments when customers wanted to pay monthly. Pipe solves this and allows us to invest more heavily in our growth. It may easily save us a fundraise.’’</div>
                            </div>
                        </div>
                        <div class="item">
                            <div role="listitem" class="testimonial__card w-dyn-item">
                                <div class="testimonial__text">"We often risked losing deals by requiring annual upfront payments when customers wanted to pay monthly. Pipe solves this and allows us to invest more heavily in our growth. It may easily save us a fundraise.’’</div>
                            </div>
                        </div>
                        <div class="item">
                            <div role="listitem" class="testimonial__card w-dyn-item">
                                <div class="testimonial__text">"We often risked losing deals by requiring annual upfront payments when customers wanted to pay monthly. Pipe solves this and allows us to invest more heavily in our growth. It may easily save us a fundraise.’’</div>
                            </div>
                        </div>
                        <div class="item">
                            <div role="listitem" class="testimonial__card w-dyn-item">
                                <div class="testimonial__text">"We often risked losing deals by requiring annual upfront payments when customers wanted to pay monthly. Pipe solves this and allows us to invest more heavily in our growth. It may easily save us a fundraise.’’</div>
                            </div>
                        </div>
                        <div class="item">
                            <div role="listitem" class="testimonial__card w-dyn-item">
                                <div class="testimonial__text">"We often risked losing deals by requiring annual upfront payments when customers wanted to pay monthly. Pipe solves this and allows us to invest more heavily in our growth. It may easily save us a fundraise.’’</div>
                            </div>
                        </div>

                        <div class="owl-nav disabled"> </div>
                         <button type="button" role="presentation" class="owl-prev">
                            <div class="nav-btn prev-slide"></div>
                         </button>
                         <button type="button" role="presentation" class="owl-next">
                            <div class="nav-btn next-slide"></div>
                         </button>
                    </OwlCarousel>
                </div> */}



                <div className="eyeMainRev">
                    <div data-aos-once="true" className="downloadHead" style={{padding: 80}}>
                        <Image alt="Invoice generator payment screens" data-aos-anchor-placement="top-bottom" data-aos-delay="300" data-aos="fade-right" data-aos-easing="ease-out-cubic" data-aos-once="true" className="gapHome2" src={require('../home/images/WebPay.png')}/>
                        <div  className="textClass" data-aos-anchor-placement="top-bottom" data-aos="fade-up" data-aos-delay="300" data-aos-once="true" data-aos-easing="ease-out-cubic" style={{fontSize: 50, fontWeight: 650, color: '#fff', marginBottom: 50}}>
                            One-touch payments
                            <p style={{fontSize: 28, fontWeight: 400, color: 'gray', width: '80%'}}>Let your customers pay with one touch, get paid with instant payouts.</p>

                            <div className="cards">
                                <Image alt="Invoice generator visa card" className="gapHome3" src={require('../home/images/visa.png')}/>
                                <Image alt="Invoice generator mastercard" className="gapHome3" src={require('../home/images/mastercard.png')}/>
                                <Image alt="Invoice generator amex" className="gapHome3" src={require('../home/images/amex.png')}/>
                            </div>
                        </div>
                    </div>

                    <div data-aos-delay="300" data-aos="fade-in" data-aos-once="true" className='secondSection'>

                        <p style={{fontSize: 24, marginBottom: 30, fontWeight: 600, color: "#347CF6"}}>Are you ready for HMRC's Making Tax Digital?</p>

                        <div>
                            <p style={{color: "gray"}}>HMRC is forcing all sole traders to use a digital tax solution to submit their income and expenses 4 times per year.</p>
                            <p style={{fontSize: 20, fontWeight: 700, color: "#fff"}}>Don't get caught out.</p>



                            <div>
                                <div style={{flexDirection: 'row', marginTop: 0, marginBottom: 40, textAlign: 'center'}}>
                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.histreet.business">
                                        <Image alt="Invoice generator play store" className="gap" src={require('../home/images/icon/playstore.png')}/>
                                    </a>
                                    <a target="_blank" href="https://apps.apple.com/gb/app/histreet-business/id1494624906">
                                        <Image alt="Invoice generator app store" className="gap" src={require('../home/images/icon/appstore.png')}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Ftr/>
            </div>
        )
    }

    /**
     */
  // class Eye_Home

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Home_Home )


