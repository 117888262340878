/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {
    Nav, Navbar,
    Container, Image,
} from 'react-bootstrap'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import { navigate } from "@reach/router"
import './css/hdr.css'

/**
 */
class Hdr extends React.PureComponent
{
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render()
    {
        return (
            <div style={{position:"sticky",top:0}} fixed="top" expand="lg">

                <div className="hdrDiv">
                    <Image alt="Histreet logo" className="logo" src={require('./images/56.png')}/>
                    <div className="hdrText">
                        {this.props.bizName}
                    </div>
                </div>
            </div>
        )
    }
}   // class Hdr

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Hdr )


