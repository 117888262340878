/**
 * @copyright Elmelo Ltd.
 */

import React, {useState} from 'react'

// import {connect} from 'react-redux'
// import * as actions from '../../rdx/actions'

import {navigate} from "@reach/router"

import {Image, Modal} from "react-bootstrap";
import './invoice.css'

import elml_cfg from '../../_config/elml_cfg'

import {Lambda,} from '../../api/AWS'
import Loader from "react-loader-spinner";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CardForm from './cardForm'

const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const stripePromise = loadStripe(elml_cfg.stripe.key[elml_cfg.stage]);

/**
 */
const Invoice = (props) => {
    const [isInit, SetIsInit] = React.useState(false)
    const [metaObj, SetMetaObj] = React.useState(null)
    const [bizImg, SetBizImg] = React.useState(null)
    const [payMethod, SetPayMethod] = React.useState(false);
    const [processing, setProcessing] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [imageModalShow, setImageModalShow] = React.useState(false);
    const [imageModalImage, setImageModalImage] = React.useState(null);
    const [currency, setCurrency] = React.useState( {country: 'GB', cur: 'GBP', sign: '£', taxname: 'VAT1'} );
    const [taxname, setTaxname] = React.useState( "VAT1" );
    const [bannerImage, setBannerImage] = React.useState("https://storage.histreet-app.com/images/banner/0.png")
    const [p_msg] = useState({
        est_viewed: 'has viewed your estimate.',
        invc_viewed: 'has viewed your invoice.',
        paid: 'has settled your invoice 💸',
        agreed: 'has agreed to your estimate! 🤝',

    })

    /**
     */
    React.useEffect(() => {
        Init()
            .then((item) => {
                getBiz(item).catch();
                // SendPush(item,'paid').then()
            })
            .catch();

    }, []);


    function hexToRgbA(hex) {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.2)';
        }
        throw new Error('Bad Hex');
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    /**
     */
    const Init = async () => {
        try {
            const aws_lambda = new Lambda({})

            SetIsInit(true)

            const p_lambda =
                {
                    stage: elml_cfg.stage,
                    actType: "invoice",
                    act: "invoice:getmd",
                    data: {
                        uid: decodeURIComponent(props.meta),
                    }
                };

            const resp_lambda = await aws_lambda.Invoke(p_lambda, elml_cfg.lambda('unauth', elml_cfg.stage));

            if (!resp_lambda) {
                throw new Error('Invoice not found.');
            }

            const data = resp_lambda.Item;

            SetMetaObj(data);

            if( data?.currency )
                setCurrency( data.currency )

            if( data?.currency?.taxname )
                setTaxname( data.currency.taxname )

            if (data.inv_settings)
                setBannerImage(data.inv_settings.banner);

            if (data && data.type.toLowerCase() === 'estimate' && (data.status.trim().toLowerCase() === 'pending' || data.status.trim().toLowerCase() === 'edited')) {
                await UpdateEstimateStatus("viewed", data)
                await SendPush(data, 'est_viewed');
            }
            if (data && data.type.toLowerCase() === 'invoice' && (data.status.trim().toLowerCase() === 'new' || data.status.trim().toLowerCase() === 'pending')) {
                await UpdateEstimateStatus("Viewed", data)
                await SendPush(data, 'invc_viewed');
            }

            SetIsInit(false);

            return resp_lambda.Item;
        } catch (err) {
            console.warn('Invoice: Init: err: ', err)

            SetIsInit(false)

            alert(err.message)

            return {err}
        }
    }   // Init

    /**
     *
     */
    const getBiz = async (item) => {
        try {
            if (item) {
                const aws_lambda = new Lambda({});

                const p_lamdba =
                    {
                        stage: elml_cfg.stage,
                        actType: "biz",
                        act: "biz:getinfo",

                        biz_id: item.biz_id
                    };

                const resp_lambda = await aws_lambda.Invoke(p_lamdba, elml_cfg.lambda('unauth', elml_cfg.stage));

                const _img = resp_lambda.biz_images.icon_app ? resp_lambda.biz_images.icon_app : null;

                SetBizImg(_img.img_url);

                if (resp_lambda.setup_progress.pay_method)
                    SetPayMethod(resp_lambda.setup_progress.pay_method)
            }
        } catch (err) {
            console.error("invoice2 : Invoice : getBiz : err : ", err);
        }
    }

    /**
     */
    const OnClick = () => {
        try {
            if (!metaObj) {
                alert('Reserve object not ready')

                return {}
            }

            const data = encodeURIComponent(JSON.stringify({uid: metaObj.uid, stage: props.stage}))

            const link = `https://histreet-app.com/invoice/?data:${data}`

            const deep_link = `https://histreet.page.link/?link=${link}&ifl=https://testflight.apple.com/join/Hnhxjs9z&ibi=com.histreetapp.app&apn=com.histreet.app&ofl=https://histreet-app.com/invoice/${props.stage}/${encodeURIComponent(metaObj.uid)}`

            // const testFlight = "&ifl=https://testflight.apple.com/join/Hnhxjs9z"
            // const appStore = "&isi=1477813443"

            navigate(encodeURI(deep_link))

        } catch (err) {
            console.warn('Invoice: OnClick: err: ', err)
        }
    }   // OnClick
    /**
     */
    const UpdateEstimateStatus = async (status, item) => {
        try {
            const aws_lambda = new Lambda({});

            setProcessing(true);

            const p_lambda = {
                stage: elml_cfg.stage,
                actType: "invoice",
                act: "estimate:status:upd",

                biz_id: item.biz_id,
                dt_create: item.dt_create,

                status: status
            };

            const resp_lambda = await aws_lambda.Invoke(p_lambda, elml_cfg.lambda('unauth', elml_cfg.stage));

            if (resp_lambda.msg === 'OK') {
                item.status = status
                SetMetaObj(item)
                if (status === 'agreed') {
                    await SendPush(item, 'agreed')
                }
            }

            setProcessing(false);

            return resp_lambda;

        } catch (err) {
            setProcessing(false);

            console.warn('Invoice: UpdateEstimateStatus: err: ', err)
        }
    }   // UpdateEstimateStatus

    const SendPush = async (data, type) => {
        try {
            // return {}
            const aws_lambda = new Lambda({});
            const p_lambda = {
                stage: elml_cfg.stage,
                actType: "notify",
                act: "notify:push",

                notification: {
                    title: data.invc_cus.name ? data.invc_cus.name : data.c_name ? data.c_name : 'Customer',
                    body: p_msg[type],
                    collapse_key: data.biz_id
                },
                data: {
                    title: data.invc_cus.name ? data.invc_cus.name : data.c_name ? data.c_name : 'Customer',
                    body: p_msg[type],
                    inv_uid: data.uid,
                    biz_id: data.biz_id,
                    notification_type: type
                },
                pub_id_list: [data.biz_id]
            };
            const resp_lambda = await aws_lambda.Invoke(p_lambda, elml_cfg.lambda('unauth', elml_cfg.stage));
            //
            // return resp_lambda
        } catch (e) {
            console.warn('Invoice2: SendPush:err', e)
            return Promise.reject(e)
        }
    }

    /**
     */
    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
            {
                isInit ?

                    <div style={{
                        display: "flex",
                        flex: 1,
                        marginTop: 32,
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Loader
                            type="Puff"
                            color="#466099"
                            height={160}
                            width={160}
                        />
                    </div> :
                    <div className="invoiceBg">
                        <>
                            {
                                metaObj
                                    ?
                                    <div className="outerDiv">
                                        <div className="innerDiv">
                                            <Image
                                                style={{
                                                    width: "100%",
                                                    height: 100,
                                                }}
                                                src={bannerImage}
                                            />
                                            <div style={{paddingRight: 8, paddingLeft: 8}}>
                                                <div style={{display: "flex", flexDirection: "row"}}>
                                                    {
                                                        bizImg
                                                            ?
                                                            <div style={{
                                                                flex: 1,
                                                                paddingRight: 8,
                                                                textAlign: "right",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "flex-end"
                                                            }}>
                                                                <Image
                                                                    style={{
                                                                        width: 140,
                                                                        height: 120,
                                                                        resizeMode: "cover",
                                                                        // borderRadius: 50,
                                                                        // marginLeft: 8
                                                                    }}
                                                                    src={bizImg}
                                                                />
                                                            </div>
                                                            :
                                                            <div className="imageClass">
                                                                <div
                                                                    className="imageBigText">{metaObj.b_name ? metaObj.b_name.charAt(0) : '#'}</div>
                                                            </div>
                                                    }
                                                    <div style={{
                                                        flex: 1,
                                                        textAlign: "right",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "flex-end"
                                                    }}>
                                                        <div style={{
                                                            fontSize: 25,
                                                            color: "#707070"
                                                        }}>{metaObj.type.toLowerCase() === 'estimate' ? "ESTIMATE" : "INVOICE"}</div>
                                                        <div style={{
                                                            fontSize: 18,
                                                            color: "#707070"
                                                        }}>{metaObj.type.toLowerCase() === 'estimate' ? null : metaObj.dt_create}</div>
                                                        <div style={{
                                                            fontSize: 18,
                                                            color: "#707070"
                                                        }}>{month[new Date(metaObj.dt_create * 1000).getMonth()]} {new Date(metaObj.dt_create * 1000).getDate()}, {new Date(metaObj.dt_create * 1000).getFullYear()} </div>
                                                        {/* <div style={{fontSize:18,fontWeight:700, color:"#707070"}}>Due: {month[new Date(metaObj.dt_create * 1000).getMonth()]} {new Date(metaObj.dt_create * 1000).getDate()}, {new Date(metaObj.dt_create * 1000).getFullYear()} </div> */}
                                                    </div>
                                                </div>
                                                <div style={{display: "flex", flexDirection: "row"}}>
                                                    <div className="nameDiv">
                                                        <div className="smallText" style={{fontWeight: "700"}}>Bill
                                                            To:
                                                        </div>
                                                        <div
                                                            className="bigText">{metaObj.invc_cus && metaObj.invc_cus.name ? metaObj.invc_cus.name : "Customer Name"}</div>
                                                        <div className="smallText">
                                                            {
                                                                metaObj.invc_cus && metaObj.invc_cus.addr
                                                                    ?
                                                                    metaObj.invc_cus.addr.line1 ? metaObj.invc_cus.addr.line1 : " " :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="smallText">
                                                            {
                                                                metaObj.invc_cus && metaObj.invc_cus.addr
                                                                    ? metaObj.invc_cus.addr.line2 ? metaObj.invc_cus.addr.line2 : " "
                                                                    :
                                                                    null
                                                            }
                                                        </div>

                                                        <div className="smallText">
                                                            {
                                                                metaObj.invc_cus && metaObj.invc_cus.addr
                                                                    ?
                                                                    [
                                                                        metaObj.invc_cus.addr.county ? metaObj.invc_cus.addr.county : " ",
                                                                    ]
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="smallText">
                                                            {
                                                                metaObj.invc_cus && metaObj.invc_cus.addr
                                                                    ?
                                                                    [
                                                                        metaObj.invc_cus.addr.postcode ? metaObj.invc_cus.addr.postcode : " ",
                                                                    ]
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="nameDiv" style={{
                                                        justifyContent: "flex-end",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "flex-end",
                                                        textAlign: "right"
                                                    }}>
                                                        <div
                                                            className="bigText"
                                                            style={{textAlign: "right"}}>{metaObj.b_name ? metaObj.b_name : ''}</div>
                                                        <div className="smallText">
                                                            {
                                                                metaObj.b_addr
                                                                    ?
                                                                    metaObj.b_addr.line1 ? metaObj.b_addr.line1 : " "
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="smallText">
                                                            {
                                                                metaObj.b_addr
                                                                    ?
                                                                    metaObj.b_addr.line2 ? metaObj.b_addr.line2 : " "
                                                                    :
                                                                    null
                                                            }
                                                        </div>

                                                        <div className="smallText">
                                                            {
                                                                metaObj.b_addr
                                                                    ?
                                                                    [
                                                                        metaObj.b_addr.county ? metaObj.b_addr.county : " ",
                                                                    ]
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="smallText">
                                                            {
                                                                metaObj.b_addr
                                                                    ?
                                                                    [
                                                                        metaObj.b_addr.postcode ? metaObj.b_addr.postcode : " ",
                                                                    ]
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <>
                                                {
                                                    metaObj.items
                                                        ? <div>
                                                            <div className="itemGradient"
                                                                 style={{
                                                                     backgroundColor: metaObj.inv_settings ? hexToRgbA(metaObj.inv_settings.color) : "#fff",
                                                                     borderTopColor: metaObj.inv_settings ? metaObj.inv_settings.color : "#000",
                                                                     borderBottomColor: metaObj.inv_settings ? metaObj.inv_settings.color : "#000"
                                                                 }}
                                                            >
                                                                <div className="itemGradientInner">
                                                                    <div className="bigTextCenter"
                                                                         style={{textAlign: "left"}}>Description / Quantity
                                                                        / Rate
                                                                    </div>
                                                                    <div className="smallCenter">{taxname}</div>
                                                                    {/*<div className="bigTextCenter">Quantity</div>*/}
                                                                    <div className="smallCenter"
                                                                         style={{textAlign: "right", flex: 0.5}}>Amount
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                {
                                                                    metaObj.items.map((item, idx) => (
                                                                        <div className="itemDiv">
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "row"
                                                                            }}>
                                                                                <div className="itemText"
                                                                                     style={{textAlign: "left", flex: 1.3}}>
                                                                                    {item.name}
                                                                                </div>
                                                                                <div className="itemText" style={{
                                                                                    flex: 0.3,
                                                                                    textAlign: "right"
                                                                                }}>
                                                                                    {item.vat ? item.vat : '0'}{'%'}
                                                                                </div>
                                                                                {/*<div className="itemText">*/}
                                                                                {/*    {item.quantity}*/}
                                                                                {/*</div>*/}
                                                                                <div className="itemText" style={{
                                                                                    textAlign: "right",
                                                                                    flex: 0.5
                                                                                }}>
                                                                                    {currency.sign} {numberWithCommas((Number(item.price.def * item.quantity)).toFixed(2))}
                                                                                </div>
                                                                            </div>
                                                                            <div className="itemText"
                                                                                 style={{textAlign: "left"}}>
                                                                                <span>{item.quantity}</span> x <span> {currency.sign}{numberWithCommas((Number(item.price.def)).toFixed(2))}</span>
                                                                            </div>
                                                                        </div>

                                                                    ))
                                                                }
                                                            </div>
                                                            <div style={{paddingTop: 12, paddingBottom: 12}}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    paddingTop: 2,
                                                                    paddingRight: 8
                                                                }}>
                                                                    <div style={{
                                                                        fontFamily: 'Open Sans',
                                                                        fontSize: 16,
                                                                        color: "#707070",
                                                                        flex: 1.6,
                                                                        textAlign: "right",
                                                                    }}>Subtotal
                                                                    </div>
                                                                    <div style={{
                                                                        fontFamily: 'Open Sans',
                                                                        color: "#707070",
                                                                        flex: 0.5,
                                                                        // width: 120,
                                                                        textAlign: "right"
                                                                    }}>{currency.sign} {numberWithCommas((metaObj.a_sub / 100).toFixed(2))}</div>
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    paddingTop: 2,
                                                                    paddingRight: 8
                                                                }}>
                                                                    <div style={{
                                                                        fontFamily: 'Open Sans',
                                                                        fontSize: 16,
                                                                        color: "#707070",
                                                                        flex: 1.6,
                                                                        textAlign: "right",
                                                                    }}>
                                                                        {taxname}
                                                                    </div>
                                                                    <div style={{
                                                                        fontFamily: 'Open Sans',
                                                                        color: "#707070",
                                                                        flex: 0.5,
                                                                        // width: 120,
                                                                        textAlign: "right"
                                                                    }}>
                                                                        {currency.sign} {numberWithCommas((metaObj.a_vat / 100).toFixed(2))}
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div
                                                                className="totalDiv"
                                                                style={{
                                                                    borderTopColor: metaObj.inv_settings ? metaObj.inv_settings.color : "#707070"
                                                                }}
                                                            >
                                                                <div style={{
                                                                    fontFamily: 'Open Sans',
                                                                    fontWeight: 700,
                                                                    color: "#707070",
                                                                    flex: 1.6,
                                                                    justifyContent: "flex-end",
                                                                }}>Total
                                                                </div>
                                                                <div style={{
                                                                    fontFamily: 'Open Sans',
                                                                    fontWeight: 700,
                                                                    color: "#707070",
                                                                    flex: 0.5,
                                                                    textAlign: "right"
                                                                }}>{currency.sign} {numberWithCommas((metaObj.a_tot / 100).toFixed(2))}</div>
                                                            </div>

                                                            {/*<div className="prices">*/}
                                                            {/*    <div style={{paddingTop:16,paddingBottom:16,marginRight:8}}>*/}
                                                            {/*        <div style={{display: "flex", flexDirection: "row",}}>*/}
                                                            {/*            <div style={{fontFamily: 'Open Sans',*/}
                                                            {/*                fontSize:16,*/}
                                                            {/*                color: "#707070",*/}
                                                            {/*                flex:2,*/}
                                                            {/*            }}>Subtotal</div>*/}
                                                            {/*            <div style={{*/}
                                                            {/*                fontFamily: 'Open Sans',*/}
                                                            {/*                color: "#707070",*/}
                                                            {/*                flex:1,*/}
                                                            {/*                // width: 120,*/}
                                                            {/*                textAlign: "right"*/}
                                                            {/*            }}>/!* {metaObj.currency && metaObj.currency.sign ? metaObj.currency.sign : '$'} *!/£ {numberWithCommas((metaObj.a_sub / 100).toFixed(2))}</div>*/}
                                                            {/*        </div>*/}
                                                            {/*        <div style={{display: "flex", flexDirection: "row",justifyContent:"flex-end"}}>*/}
                                                            {/*            <div style={{fontFamily: 'Open Sans',*/}
                                                            {/*                fontSize:16,*/}
                                                            {/*                color: "#707070",}}>VAT</div>*/}
                                                            {/*            <div style={{*/}
                                                            {/*                fontFamily: 'Open Sans',*/}
                                                            {/*                color: "#707070",*/}
                                                            {/*                width: 120,*/}
                                                            {/*                textAlign: "right"*/}
                                                            {/*            }}>/!* {metaObj.currency && metaObj.currency.sign ? metaObj.currency.sign : '$'} *!/£ {numberWithCommas((metaObj.a_vat / 100).toFixed(2))}</div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </div>*/}

                                                            {/*    <div*/}
                                                            {/*        className="totalDiv"*/}
                                                            {/*        style={{*/}
                                                            {/*            borderTopColor: metaObj.inv_settings ? metaObj.inv_settings.color : "#707070"*/}
                                                            {/*        }}*/}
                                                            {/*    >*/}
                                                            {/*        <div style={{fontFamily: 'Open Sans',*/}
                                                            {/*            fontWeight: 700,*/}
                                                            {/*            color: "#707070",}}>Total</div>*/}
                                                            {/*        <div style={{*/}
                                                            {/*            fontFamily: 'Open Sans',*/}
                                                            {/*            fontWeight: 700,*/}
                                                            {/*            color: "#707070",*/}
                                                            {/*            width: 120,*/}
                                                            {/*            textAlign: "right"*/}
                                                            {/*        }}>/!* {metaObj.currency && metaObj.currency.sign ? metaObj.currency.sign : '$'} *!/£ {numberWithCommas((metaObj.a_tot / 100).toFixed(2))}</div>*/}
                                                            {/*    </div>*/}

                                                            {/*    /!* <div*/}
                                                            {/*        className="totalDiv"*/}
                                                            {/*        style={{*/}
                                                            {/*            borderTopColor: "#707070"*/}
                                                            {/*        }}*/}
                                                            {/*    >*/}
                                                            {/*        <div style={{fontFamily: 'Open Sans',*/}
                                                            {/*            fontSize:16,*/}
                                                            {/*            color: "#707070",}}>Paid</div>*/}
                                                            {/*        <div style={{*/}
                                                            {/*            fontFamily: 'Open Sans',*/}
                                                            {/*            color: "#707070",*/}
                                                            {/*            width: 120,*/}
                                                            {/*            textAlign: "right"*/}
                                                            {/*        }}>{metaObj.currency && metaObj.currency.sign ? metaObj.currency.sign : '$'}{numberWithCommas((metaObj.a_tot / 100).toFixed(2))}</div>*/}
                                                            {/*    </div>*/}

                                                            {/*    <div*/}
                                                            {/*        className="dueDiv"*/}
                                                            {/*    >*/}
                                                            {/*        <div style={{fontFamily: 'Open Sans',*/}
                                                            {/*            fontWeight: 700,*/}
                                                            {/*            color: "#707070",}}>Balance Due</div>*/}
                                                            {/*        <div style={{*/}
                                                            {/*            fontFamily: 'Open Sans',*/}
                                                            {/*            fontWeight: 700,*/}
                                                            {/*            color: "#707070",*/}
                                                            {/*            width: 120,*/}
                                                            {/*            textAlign: "right"*/}
                                                            {/*        }}>{metaObj.currency && metaObj.currency.sign ? metaObj.currency.sign : '$'}{numberWithCommas((metaObj.a_tot / 100).toFixed(2))}</div>*/}
                                                            {/*    </div> *!/*/}
                                                            {/*</div>*/}
                                                        </div>

                                                        : null
                                                }
                                            </>
                                        </div>
                                        {
                                            ((metaObj.notes && metaObj.notes !== "") || (metaObj.attachments && metaObj.attachments.length))
                                                ?
                                                <div className="innerDiv" style={{padding: 16, marginTop: 16}}>
                                                    {
                                                        (metaObj.notes && metaObj.notes !== "")
                                                            ?
                                                            <>
                                                                <div className="bigText">{"Notes"}</div>
                                                                <div className="smallText"
                                                                     style={{marginTop: 16}}>{metaObj.notes}</div>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        (metaObj.attachments && metaObj.attachments.length)
                                                            ?
                                                            <>
                                                                <div className="bigText" style={{
                                                                    marginTop: 16,
                                                                    marginBottom: 16
                                                                }}>{"Attachments"}</div>
                                                                <div style={{flexDirection: "row"}}>
                                                                    {
                                                                        metaObj.attachments.map(item => (
                                                                            <Image
                                                                                onClick={() => {
                                                                                    setImageModalShow(true)
                                                                                    setImageModalImage(item)
                                                                                }}
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    width: 65,
                                                                                    height: 65,
                                                                                    borderRadius: 8,
                                                                                    marginRight: 16
                                                                                }}
                                                                                src={item}
                                                                            />
                                                                        ))
                                                                    }

                                                                    <Modal
                                                                        show={imageModalShow}
                                                                        onHide={() => setImageModalShow(false)}
                                                                        size="lg"
                                                                        aria-labelledby="contained-modal-title-vcenter"
                                                                        centered
                                                                    >
                                                                        <Modal.Body>
                                                                            <Image
                                                                                onClick={() => setImageModalShow(true)}
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    width: "100%",
                                                                                    height: "auto",
                                                                                    borderRadius: 8,
                                                                                    marginRight: 8
                                                                                }}
                                                                                src={imageModalImage}
                                                                            />
                                                                        </Modal.Body>
                                                                    </Modal>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                :
                                                null

                                        }
                                    </div>

                                    : null
                            }
                        </>
                        {
                            // metaObj && metaObj.type.toLowerCase() === 'invoice' && payMethod
                            //     ?
                            //     metaObj && metaObj.paid_status === "paid"
                            //         ?
                            //         <div style={{flex: 1, padding: 50}}>
                            //             <div className="greenButton">Paid</div>
                            //         </div>
                            //         :
                            //         <div>

                            //             <div className="payText">
                            //                 <Elements stripe={stripePromise}>
                            //                     <CardForm invoice={metaObj}
                            //                               onSuccess={obj => {
                            //                                   SetMetaObj(obj);
                            //                                   setPaymentSuccess(true);
                            //                                   SendPush(obj, 'paid').then()
                            //                               }}
                            //                     />
                            //                 </Elements>
                            //             </div>
                            //             {/* <div className="payText">or pay through HiStreet</div>
                            //             <div
                            //                 // type="submit"
                            //                 onClick={OnClick}
                            //                 className="invoiceBtn">
                            //                 <div className="getBtnText">
                            //                     Open HiStreet
                            //                 </div>
                            //             </div> */}

                            //             {/* <div className="payText"> Or pay with BACS</div>
                            //             <div className="accountText">Account Holder Name</div>
                            //             <div className="valueText">JJ Plumbing</div>
                            //             <div className="accountText">Account Number</div>
                            //             <div className="valueText">123456789</div>
                            //             <div className="accountText">Sort Code</div>
                            //             <div className="valueText">00-00-00-00</div> */}
                            //         </div>
                            //     :
                            metaObj && metaObj.type.toLowerCase() === 'estimate' && (metaObj.status.toLowerCase() === 'pending' || metaObj.status.toLowerCase() === 'viewed' || metaObj.status.toLowerCase() === 'edited')
                                ?
                                <div
                                    onClick={() => processing ? {} : UpdateEstimateStatus('agreed', metaObj)}
                                    className="invoiceBtn"
                                >
                                    {
                                        processing
                                            ?
                                            <div className="getBtnText">Processing...</div>
                                            :
                                            <div className="getBtnText">
                                                Agree
                                            </div>
                                    }
                                </div>
                                :
                                metaObj && metaObj.type.toLowerCase() === 'estimate' && metaObj.status.toLowerCase() === 'agreed'
                                    ?
                                    <div
                                        // onClick={OnAgreeClick}
                                        className="invoiceBtn">
                                        <div className="getBtnText">
                                            Agreed
                                        </div>
                                    </div>
                                    :
                                    // <div style={{flex: 1, padding: 50}}>
                                    //     <div className="redButton">Payment methods not initialized.</div>
                                    // </div>
                                    <div style={{flex: 1, padding: 0}}>
                                        {/* <div className="greenButton">Pay directly to the business.</div> */}
                                    </div>
                        }
                    </div>
            }
            {
                // paymentSuccess &&
                // <div className="payDone">
                //     <div className="payDoneDiv">
                //         <Image src={require('../assets/imageIcon/tick.png')}/>
                //         <div className="payDivText">Payment Success!</div>
                //         <div className="invoiceBtn">
                //             <div className="getBtnText" onClick={() => setPaymentSuccess(false)}>
                //                 Done
                //             </div>
                //         </div>
                //     </div>
                // </div>
            }
            {/* <div className="localText">
                Pay faster, pay safer
            </div> */}

            <Image alt="invoice24 logo" className="hsImage" src={require('../assets/imageIcon/Invoice24LIGHTBACK.png')}/>

        </div>
    )
}

export default Invoice

