/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {
    Nav, Navbar,
    Container, Image,
} from 'react-bootstrap'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import {navigate} from "@reach/router";
import '../home/css/eye.css';


/**
 */
class Ftr extends React.PureComponent
{
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render()
    {
        return (
            <Navbar class="navbar-fixed-bottom" sticky="bottom" expand="lg" style={{
                                                                                backgroundColor: "#24243A", 
                                                                                height: "9%", 
                                                                                marginBottom: 0, 
                                                                                }}>
                <Nav className="mr-auto ml-auto" style={{flexDirection: "column"}}>
                    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css" rel="stylesheet"/>
                    <div class="text-center" style={{fontSize: 34}}>
                        <a alt="Invoice generator twitter" href="https://twitter.com/i24uk" style={{marginRight: 8, marginLeft: 8}}><i class="fa fa-twitter-square white-color"></i></a>
                        <a alt="Invoice generator linkedin" href="https://www.linkedin.com/company/histreetuk/" style={{marginRight: 8, marginLeft: 8}}><i class="fa fa-linkedin-square white-color"></i></a>
                        <a alt="Invoice generator instagram" href="https://www.instagram.com/i24uk/" style={{marginRight: 8, marginLeft: 8}}><i class="fa fa-instagram white-color"></i></a>
                    </div>
                
                    <Nav className="mr-auto ml-auto" style={{alignItems: "center", marginBottom: 40}}>

                        {/* <Nav>
                            <Nav.Link className="ftrLink3" href='blog'>Blog</Nav.Link>
                        </Nav>

                        <Nav style={{padding: 10, color: "#333"}}>
                            |
                        </Nav> */}
                        
                        <Nav>
                            <Nav.Link className="ftrLink4" href='careers'>Careers</Nav.Link>
                        </Nav>

                        <Nav className="bar" style={{padding: 10, color: "#fff"}}>
                            |
                        </Nav> 

                        <Nav style={{display:"flex",flexDirection:"row", flex: 1}}>
                            {/*<Nav.Link className="ftrLink" href='menu'>Sitemap</Nav.Link>*/}
                            {/*<Nav.Link className="ftrLink">Careers</Nav.Link>*/}
                            {/*<Nav.Link className="ftrLink">Contact</Nav.Link>*/}
                            {/*<Nav.Link className="ftrLink">Blogs</Nav.Link>*/}
                            {/*<Nav.Link className="ftrLink">Investment opportunities</Nav.Link>*/}
                            <Nav.Link className="ftrLink" href='termscondition'>Terms and Conditions</Nav.Link>
                        </Nav>

                        <Nav className="bar" style={{padding: 10, color: "#fff"}}>
                            |
                        </Nav>

                        <Nav>
                            <Nav.Link className="ftrLink2" href='privacypolicy'>Privacy Policy</Nav.Link>
                        </Nav>
                    </Nav>
                    </Nav>
            </Navbar>
            )
    }
}   // class Ftr

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Ftr )


