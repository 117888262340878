/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import { Router, navigate } from "@reach/router"

// import {StripeProvider, Elements} from 'react-stripe-elements'

import 'bootstrap/dist/css/bootstrap.min.css'

import {connect} from 'react-redux'
import * as actions from './rdx/actions'

import elml_cfg from './_config/elml_cfg'

import {
    // App_Auth,
    AuthLogin, AuthSignIn, AuthSignUp,
    AuthVerification, AuthForgotten, AuthLogout
} from './components/auth'

// import {
//     App_Chat,
//     Chat_Home, Chat_Inst, Chat_Settings
// } from './components/chat'

import {
    CheckoutHome,Checkout_Success,
    Checkout_Personal, Checkout_Payment, Checkout_Summary
} from './components/checkout'

// import {
//     App_Eye, Eye_Home, Eye_User
// } from './components/eye'

import {
    OrderHome, //Order_Info, Order_Cart, Order_Details
} from './components/order'

// import {
//     App_Profile, Profile_Home, Profile_AddCard,
// } from './components/profile'

// import {
//     App_Rsrv,
//     Rsrv_Home, Rsrv_Hist, Rsrv_New, Rsrv_Details
// } from './components/reserve'

// import {
//     App_Search,
//     Search_Cats, Search_Subcats, Search_Results, Search_Recom, Search_Biz
// } from './components/search'

import
{
    HomeHome, HomeBusiness,
} from './components/home'

import
{
    Contact_Home
} from './components/contact'

import
{
    Generator_Home
} from './components/invoiceGenerator'

import
{
   Team_Home
} from './components/team'

import
{
    Privacy_Home
} from './components/privacy'

import
{
    Term_Home
} from './components/term'

import
{
    Download_Home
} from './components/download'

// import
// {
//     About_Home
// } from './components/about'
import
{
    Careers_Home
} from './components/careers'



import P404 from './components/_common/404'

import {Core} from './api/AWS'
import Invoice from './components/invoice/invoice'
import Invoice2 from './components/invoice/invoice2'

// class ScProf_AddCard extends React.PureComponent
// {
//     //
//     constructor( props )
//     {
//         super( props )
//     }

//     //
//     render()
//     {
//         console.warn( 'ScProf_AddCard: this.props: ', this.props )

//         return (
//             <StripeProvider stripe={this.props.stripe}>
//                 <Elements>
//                     <Profile_AddCard />
//                 </Elements>
//             </StripeProvider>
//             )
//     }
// }   // ScProf_AddCard

/**
 */
class App extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )

        this.state = {stripe: null}

        this._idInt = -1
    } // cstr

    /**
     */
    render()
    {
      return (
        <div>
            <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
            integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
            crossOrigin="anonymous"
            />

            <Router>
                <HomeHome path='/' />
                <HomeHome path='home' />
                <HomeBusiness path='business' />
                {/*<About_Home path='about' />*/}
                <Careers_Home path='careers' />
                <Contact_Home path='contact' />
                <Generator_Home path='invGen' />
                <Team_Home path='/team' />
                <Download_Home path='download' />
                <Privacy_Home path='privacypolicy' />
                <Term_Home path='termscondition' />

                <OrderHome path='order' />
                <OrderHome path='list/:bizId/order' />
                <OrderHome path='list/:bizId/order/:orderType' />

                <CheckoutHome path='order/checkout' />
                <Checkout_Success path='order/success' />

                {/*
                <Order_Info path='/order/info' />
                <Order_Cart path='/order/cart' />
                <Order_Details path='/order/details' />
                */}

                {/*
                <Eye_Home path='/eye' />
                <Eye_User path='/eye/user' />

                <Search_Cats path='/search/category' />
                <Search_Subcats path='/search/subcategory' />
                <Search_Results path='/search/result' />
                <Search_Recom path='/search/recommendation' />
                <Search_Biz path='/search/business' />

                <Chat_Home path='/chat/channels'/>
                <Chat_Inst path='/chat/inst' />
                <Chat_Settings path='/chat/settings' />

                <Rsrv_Home path='/reserve' />
                <Rsrv_Hist path='/reserve/history' />
                <Rsrv_New path='/reserve/new' />
                <Rsrv_Details path='/reserve/details' />

                <Checkout_Personal path='/checkout' />
                <Checkout_Payment path='/checkout/payment' />
                <Checkout_Summary path='/checkout/summary' />

                <Profile_Home path='/profile'/>
                <ScProf_AddCard path='/profile/addcard' stripe={this.state.stripe}/>
                */}

                <AuthLogin path='auth/login'/>
                <AuthSignIn path='auth/signin' />
                <AuthSignUp path='auth/signup' />
                <AuthVerification path='auth/verification' />
                <AuthForgotten path='auth/forgotten' />
                <AuthLogout path='auth/logout' />

                {/* <Invoice path='invoices' />
                <Invoice path='invoices/:beta_id' />
                <Invoice path='invoices/:beta_id/:rsrv_dt' />
                <Invoice path='invoices/:beta_id/:rsrv_dt/:meta' /> */}

                 {/*<Invoice path='invoice/:meta' />*/}

                {/*
                <Invoice2 path='inv/:biz_id/:dt_create' />
                <Invoice2 path='inv_test/:biz_id/:dt_create' test_stage={true} />
                */}
                <Invoice2 path='invoice/:stage/:meta' />
                {/*
                <Invoice2 path='invc/:uid' />
                <Invoice2 path='invc_test/:uid' test_stage={true} />
                */}

                <P404 default />
            </Router>
        </div>
        )
    } // render

    /**
     */
    componentDidMount()
    {
        this.props.RdxCfg_Load()

        this.VerifyAuth().then( data => {} ).catch( err => {} )

        this.NetworkStatus().then( data => {} ).catch( err => {} )

        window.addEventListener('online', this.NetworkStatus_Wrapper)
        window.addEventListener('offline', this.NetworkStatus_Wrapper)

        // console.log( 'App: componentDidMount: stripe api key: ', elml_cfg.apiKey('stripe') );

        // //
        // if (window.Stripe)
        // {
        //     // console.log( 'App: componentDidMount: stripe: window.Stripe');
        //     this.setState({stripe: window.Stripe( elml_cfg.apiKey('stripe') )});
        // }
        // else
        // {
        //     document.querySelector('#stripe-js').addEventListener('load', () => {
        //         // console.log( 'App: componentDidMount: stripe: window.Stripe after load');

        //         // Create Stripe instance once Stripe.js loads
        //         this.setState({stripe: window.Stripe( elml_cfg.apiKey('stripe') )});
        //     });
        // }
    }   // componentDidMount

    /**
     */
    componentWillUnmount()
    {
        window.removeEventListener('online', this.NetworkStatus_Wrapper)
        window.removeEventListener('offline', this.NetworkStatus_Wrapper)
    }

    /**
     */
    NetworkStatus_Wrapper = () =>
    {
        this.NetworkStatus().then( data => {} ).catch( err => {} )
    }

    /**
     */
    NetworkStatus = async () =>
    {
        try
        {
            const condition = navigator.onLine ? 'online' : 'offline'

            if (condition === 'online')
            {
                this.props.RdxCore_bNetwork( true )

                // this._idInt = setInterval( async () => {
                //     try
                //     {
                //         await fetch( '//google.com', {node: 'no-cors',} )

                //         console.log( 'App.NetworkStatus: Internet available' )

                //         this.props.RdxCore_bInternet( true )
                //     }
                //     catch( err )
                //     {
                //         console.warn( 'App.NetworkStatus: No Internet' )

                //         this.props.RdxCore_bInternet( false )
                //     }
                // }, 10000)   // 10 sec
            }
            else
            {
                console.warn( 'App.NetworkStatus: No Network' )

                this.props.RdxCore_bNetwork( false )
                this.props.RdxCore_bInternet( false )

                if( -1 !== this._idInt )
                {
                    clearInterval( this._idInt )
                }
            }

            return {msg: 'OK'}
        }
        catch( err )
        {
            return Promise.reject( err )
        }
    }   // NetworkStatus

    /**
     */
    VerifyAuth = async () =>
    {
        try
        {
            const aws_core = new Core({})

            // console.log( 'App.VerifyAuth: 1' )

            const current_cred = await aws_core.Credentials()

            // console.log( 'App.VerifyAuth: 2' )

            if(current_cred.authenticated)
            {
                const current_user = await aws_core.currentuser()

                // console.log( 'App.VerifyAuth: 3' )

                if(current_user.attributes &&  current_user.attributes.phone_number_verified )
                {
                    this.props.RdxCore_AuthStatus( 'auth' )

                    try
                    {
                        // /*const pub_id =*/ await Social_Core.PubId()

                        // console.log( 'Login: VerifyAuthentication: pub_id: ', pub_id )
                    }
                    catch( err )
                    {
                        console.warn( 'Login: VerifyAuthentication: err => 2: ', err )
                        /// @todo what to do when PubId fails???
                    }

                    // /// navigate not needed
                }
                else
                {
                    this.props.RdxCore_AuthStatus( 'veri_reqd' )

                    navigate( '/auth/verification' )
                }
            }
            else
            {
                this.props.RdxCore_AuthStatus( 'unauth' )

                // navigate( '/auth/signin' )
            }

            // console.log( 'App.VerifyAuth: 4' )

            return {msg: 'OK'}
        }
        catch( err )
        {
            console.warn( 'App.VerifyAuth: err: ', err )
            return Promise.reject( err )
        }
    } // VerifyAuth
} // class App

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( App )
