/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'
import Hdr from '../_common/hdr'
import './css/business.css'
import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import{
    // Container,
    Image,
    Button,
    // Row,Col,
    Form,
    Modal,
} from 'react-bootstrap'
import Recaptcha from 'react-recaptcha'

import elml_cfg from "../../_config/elml_cfg"
import {
    // Core, 
    Lambda,
} from "../../api/AWS"
import Ftr from "../_common/ftr";

/**
 */
class Eye_Home extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )
        this.state={
            bookBtn:false,

            fullName: null,
            email: null,
            phoneNo: null,
            message:null,
            msgModal:false,
            error:false
        }
    }

    closeModal = (val) =>
    {
        this.setState({msgModal:false,error:false,success:false})
    }

    validateEmail = email =>
    {
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

        return expression.test(String(email).toLowerCase())
    }

    sendMail = async () => 
    {
        try{
            if( !this.state.fullName || !this.state.email || !this.state.phoneNo )
            {
                this.setState({msgModal:true,message:"Please complete all fields before continuing",error:true})
                // return alert( "Please provide all the necessary information." );
                return;
            }
            if( !this.validateEmail(this.state.email) )
            {
                this.setState({msgModal:true,message:"Provide a valid email ID.",error:true})
                // return alert( "Provide a valid email ID." );
                return;
            }
            if( !this.state.bookBtn )
            {
                this.setState({error:true,msgModal:true,message:"Prove you are a human first. Complete the captcha to move forward"})
                // return alert( "Prove you are a human first" );
                return;
            }
            this.sendHistreetMail();
            this.sendCustomerMail();

            this.clearForm();
        }
        catch( err )
        {
            console.error( "Eye_Home : SendMail : err : ", err );
        }
    }

    sendHistreetMail = () =>
    {
        let msg_body = this.state.fullName + " requested a call on " + this.state.phoneNo + " or a mail at " + this.state.email;

        const aws_lambda = new Lambda();

        const params_lambda = 
        {
            email_from: "hello@histreet-app.com",
            email_to: ["skywalker.ryan@gmail.com", "hello@histreet-app.com"],
            msg_sub: "Call request",
            msg_body: msg_body
        }

        aws_lambda.Invoke( params_lambda, elml_cfg.lambda('public') )
            .then( data => {
                    this.setState({success:true,msgModal:true,message:"Thank you for your interest in us. We will contact you shortly."})
                    // console.log( "1" );
                    // alert( "Thank you for your interest in us. We will contact you shortly." );
                } )
            .catch( err => console.error( "Lambda : err : ", err ) );
    }

    sendCustomerMail = () =>
    {
        let msg_body = "We will be in touch shortly. You can always download HiStreet Business to get started now!";

        const aws_lambda = new Lambda();

        const params_lambda = 
        {
            email_from: "hello@histreet-app.com",
            email_to: [this.state.email],
            msg_sub: "Call request",
            msg_body: msg_body
        }

        aws_lambda.Invoke( params_lambda, elml_cfg.lambda('public') )
            .then( data => {
                    // console.log( "2" );
                    // this.setState({success:true,msgModal:true,message:"Thank you for your interest in us. We will contact you shortly."})
                    // alert( "Thank you for your interest in us. We will contact you shortly." );
                } )
            .catch( err => console.error( "Lambda : err : ", err ) );
    }

    clearForm = () =>
    {
        this.setState( { fullName: "", email: "", phoneNo: "" } );
    }

    onloadCallback = () =>
    {
        //
    }

    verifyCallback = ( response ) =>
    {
        if(response) {
            this.setState({bookBtn:true});
        }
    }

    /**
     */
    render()
    {
        return (
            <div>
                <Hdr/>
                <div className="businessBg">
                    {/*<Image src={require('../assets/imageIcon/hsb.png')}/>*/}
                    <div className="businessPage">
                        <div className="contactText">
                            <div className="headerText" style={{color: "#159957", textAlign: "left"}}>
                                A PLACE TO GROW YOUR BUSINESS
                            </div>
                            <div className="bulletColumn">
                                <div className="bulletPoints">
                                    <Image alt="invoice generator signup icon" className="bulletImage" src={require('../assets/web/signup.png')}/>
                                    <div className="infoText">Seamless Self Signup</div>
                                </div>
                                <div className="bulletPoints">
                                    <Image alt="invoice generator customer icon" className="bulletImage" src={require('../assets/web/customer.png')}/>
                                    <div className="infoText">Build a Customer Base</div>
                                </div>
                                <div className="bulletPoints">
                                    <Image alt="invoice generator business icon" className="bulletImage" src={require('../assets/web/business.png')}/>
                                    <div className="infoText">Manage Your Business</div>
                                </div>
                            </div>
                            <div className="common">
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.histreet.business">
                                    <Image alt="invoice generator play store" className="gap" src={require('../home/images/icon/playstore.png')}/>
                                </a>
                                <a target="_blank" href="https://apps.apple.com/gb/app/histreet-business/id1494624906">
                                    <Image alt="invoice generator app store" className="gap" src={require('../home/images/icon/appstore.png')}/>
                                </a>
                            </div>
                        </div>
                        <div style={{flex: 1}}>
                            <Image alt="invoice generator phone icon" className="businessImage" src={require('../assets/imageIcon/phone.png')}/>
                        </div>
                    </div>
                    <div>
                        {/*<div className="common">*/}
                        {/*    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.histreet.business">*/}
                        {/*        <Image className="gap" src={require('../home/images/icon/playstore.png')}/>*/}
                        {/*    </a>*/}
                        {/*    <a target="_blank" href="https://apps.apple.com/gb/app/histreet-business/id1494624906">*/}
                        {/*        <Image className="gap" src={require('../home/images/icon/appstore.png')}/>*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                    </div>
                    <div className="headerText" style={{textAlign:"center"}}>
                        Sign up for <br/> <span style={{color: "#159957"}}> Free Support </span>
                         on growing your business
                    </div>
                    <Form>
                        <Form.Group>
                            <Form.Control className="cTextBox" type="text" placeholder="Full Name" value={this.state.fullName} onChange={ e => this.setState({ fullName: e.target.value }) }/>
                        </Form.Group>

                        <Form.Group>
                            <Form.Control className="cTextBox" type="email" placeholder="Email" value={this.state.email} onChange={ e => this.setState({ email: e.target.value }) }/>
                        </Form.Group>

                        <Form.Group>
                            <Form.Control className="cTextBox" type="number" placeholder="Phone" value={this.state.phoneNo} onChange={ e => this.setState({ phoneNo: e.target.value }) }/>
                        </Form.Group>
                        <div
                            type="submit"
                            onClick={ ()=>this.sendMail() }
                            className="smallBtn">
                            <div className="smallBtnText">
                                Book Now
                            </div>
                        </div>

                        <div className="common center" style={{marginTop:32,marginBottom:32}}>
                            <Recaptcha
                                sitekey="6Lf_edkZAAAAABhKvzQdxA_iBHxN4yG26YMac97c"
                                render="explicit"
                                verifyCallback = { this.verifyCallback }
                                onloadCallback = { this.onloadCallback }
                            />
                        </div>
                    </Form>
                </div>
                <Ftr business={true}/>
                {/*upperfooter Section*/}
                {/*<div className="sectionBgFtr">*/}
                {/*    */}
                {/*</div>*/}

                {/*messageModal*/}

                {
                    this.state.msgModal ?

                        <Modal
                            show={true}
                            onHide={()=> this.closeModal(false)}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                {/*<Modal.Title style={{fontWeight:700 , color:this.state.error? "#8a6d3b" : "#3c763d"}}> { this.state.error? " Error !!" : "Success"}</Modal.Title>*/}
                            </Modal.Header>
                            <Modal.Body className={this.state.error ? "warn" : "done"}>
                                {this.state.message}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={this.state.error ? "danger" : "success"}  onClick={()=> this.closeModal(false)}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal> :
                        null
                }
            </div>
            )
    }

    /**
     */
    componentDidMount()
    {
    }
}   // class Eye_Home

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Eye_Home )


