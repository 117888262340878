/**
 * @copyright Elmelo Ltd.
 */

import React, {useState,useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import Hdr from '../_common/hdr'
import Ftr from '../_common/ftr'
import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import './generator.css'
import {Container, Image, Button, iframe} from 'react-bootstrap'
import {navigate} from "@reach/router"
import Calendar from 'react-calendar';
import DatePicker from "react-datepicker";
import ImageUploading from 'react-images-uploading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronDown} from '@fortawesome/free-solid-svg-icons'
/**
 */

const currencyList = [
    {label: "United Kingdom", val: "GBP"},
    {label: "United States", val: "USD"},
    {label: "Euro Member Countries", val: "EUR"},
    {label: "Australia", val: "AUD"},
    {label: "Canada", val: "CAD"},
];

export default function Generator_Home(){
    const [invoiceName, setInvoiceName] = React.useState('');
    const [from, setFrom] = React.useState('');
    const [to, setTo] = React.useState('');
    const [invoiceDate, setInvoiceDate] = React.useState(new Date());
    const [dueDate, setDueDate] = React.useState(new Date());
    const [description, setDescription] = React.useState('');
    const [unitPrice, setUnitPrice] = React.useState('');
    const [unitQuantity, setUnitQuantity] = React.useState('');
    const [currency, setCurrency] = React.useState(false);
    const [showTax, setShowTax] = React.useState(false);
    const [tax, setTax] = React.useState('');
    const [taxSelector, setTaxSelector] = React.useState('');
    const [notes, setNotes] = React.useState('');
    const [priceAmount, setPriceAmount] = React.useState(false);
    const [amountDue, setAmountDue] = React.useState('');
    const [amountPaid, setAmountPaid] = React.useState('');
    const [out, setOut] = React.useState('');
    const [images, setImages] = React.useState([]);
    const [searchCurrency, setSearchCurrency] = React.useState([]);
    const [selectCurrency, setSelectCurrency] = React.useState(["USD"]);
    const [services, setServices] = useState ({
        name: '',
        quantity: '',
        vat:'',
        price: {def: ''},
        // id: Time.Epoch(),
        _type: 'basic'
    });
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return(() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };
    return (
        console.log("selectCurrency",selectCurrency),
        // console.log("screensize",screenSize.dynamicWidth),
        // console.log("screensize",screenSize.dynamicHeight),
        <div>
            <Hdr invGen={true}/>
            <div className="invGen">
                <div className="topHead">
                    <div style={{flex:1}}>
                        <ImageUploading
                            value={images}
                            onChange={onChange}
                            dataURLKey="data_url"
                        >
                            {({
                                  imageList,
                                  onImageUpload,
                                  onImageUpdate,
                                  dragProps,
                              }) => (
                                // write your building UI
                                <div className="upload__image-wrapper">

                                    {
                                        imageList.length === 0 &&
                                        <button
                                            className="imageButton"
                                            style={{height: 156, width: 156}}
                                            // style={isDragging ? { color: 'red' } : undefined}
                                            onClick={onImageUpload}
                                            {...dragProps}
                                        >
                                            + Add Your Logo
                                        </button>
                                    }


                                    {imageList.map((image, index) => (
                                        <div key={index} className="image-item" onClick={() => onImageUpdate(index)}>
                                            <img src={image['data_url']} alt="" style={{width: 156, height: 156,borderRadius:8}}/>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </ImageUploading>
                    </div>
                    <div className="region">
                        <div className="headerCurrency" onClick={()=>setCurrency(true)}>
                            {selectCurrency}
                            <FontAwesomeIcon icon={faChevronDown} size={24} color={"#000"} style={{marginLeft:8}}/>
                        </div>
                        <div style={{fontSize:30}}>
                            INVOICE
                        </div>
                    </div>
                </div>
                <div className="infoBox">
                    <div>
                        <div className="inputBox">
                            <div className="inputLabel">*Invoice Number : </div>
                            <div className="inputValue">
                                <input className="inputText" type="text" onChange={e => setInvoiceName(e.target.invoiceName)} value={invoiceName} />
                            </div>
                        </div>

                        <div className="inputBox">
                            <div className="inputLabel">*Invoice Date : </div>
                            <div className="inputValue">
                                <DatePicker
                                    style={{width:"100%"}}
                                    className="inputText"
                                    selected={invoiceDate}
                                    onChange={(invoiceDate) => setInvoiceDate(invoiceDate)}
                                    placeholderText="Select a date between today and 5 days in the future"
                                />
                                {/*<div className="Sample__container">*/}
                                {/*    <main className="Sample__container__content">*/}
                                {/*        <Calendar onChange={setInvoiceDate} value={invoiceDate} />*/}
                                {/*    </main>*/}
                                {/*</div>*/}
                                {/*<div>*/}
                                {/*    <Calendar onChange={setInvoiceDate} value={invoiceDate} />*/}
                                {/*</div>*/}
                                {/*<input className="inputText" type="text" value={invoiceDate}/>*/}
                            </div>
                        </div>

                        <div className="inputBox">
                            <div className="inputLabel">Due Date : </div>
                            <div className="inputValue">
                                <DatePicker
                                    style={{width:"100%"}}
                                    className="inputText"
                                    placeholderText={dueDate}
                                    selected={dueDate}
                                    onChange={(dueDate) => setDueDate(dueDate)} />
                                {/*<div className="Sample__container">*/}
                                {/*    <main className="Sample__container__content">*/}
                                {/*        <Calendar onChange={setInvoiceDate} value={invoiceDate} />*/}
                                {/*    </main>*/}
                                {/*</div>*/}
                                {/*<div>*/}
                                {/*    <Calendar onChange={setInvoiceDate} value={invoiceDate} />*/}
                                {/*</div>*/}
                                {/*<input className="inputText" type="text" value={invoiceDate}/>*/}
                            </div>
                            {/*<div className="inputValue">*/}
                            {/*    <input className="inputText" type="text" value={dueDate}/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div>
                        <div className="toFrom" style={{textAlign: "right"}}>*From</div>
                        <div className="inputValue">
                    <textarea
                        placeholder={screenSize.dynamicWidth > 991 ?
                            "Business Name\r\nBusiness Address\r\nEmail Address\r\nVAT Number" :
                            "Business Name\r\nBusiness Address"
                        }
                        rows={screenSize.dynamicWidth > 991 ? 7 : 2}
                        className="textAreaText" type="text" onChange={e => setFrom(e.target.from)} value={from}/>
                        </div>
                    </div>
                    <div style={{marginTop:screenSize.dynamicWidth > 991 ? -130: 0}}>
                        <div className="toFrom">*To</div>
                        <div className="inputValue">
                            <textarea
                                placeholder={screenSize.dynamicWidth > 991 ?
                                    "Customer Name\r\nCustomer Address" :
                                    "Customer Name\r\nCustomer Address"
                                }
                                rows={screenSize.dynamicWidth > 991 ? 4 : 2}
                                className="textAreaText2" type="text" onChange={e => setFrom(e.target.from)} value={from}/>
                        </div>
                    </div>
                </div>

                {
                    screenSize.dynamicWidth > 991 ?

                        <div>
                            <div className="desktopItem">
                                <div className="largeItem">Items</div>
                                <div className="smallItem">Unit Price</div>
                                <div className="smallItem">Quantity</div>
                                <div className="smallItem">Amount</div>
                            </div>

                            <div className="productRow">
                                <div style={{width:410}}>
                                    <input
                                        className="desktopInput"
                                        style={{width:"90%",textAlign:"left"}}
                                        type="text"
                                        placeholder={"Description of product or service*"}
                                        onChange={e => setDescription(e.target.description)} value={description} />
                                </div>
                                <div style={{width:130,textAlign:"center"}}>
                                    <input
                                        className="desktopInput"
                                        style={{width:"90%",textAlign:"right",color:"#000"}}
                                        type="text"
                                        placeholder={"0.00"}
                                        onChange={e => setUnitPrice(e.target.unitPrice)} value={unitPrice} />
                                </div>
                                <div style={{width:130,textAlign:"center"}}>
                                    <input
                                        className="desktopInput unitQuantity"
                                        style={{width:"90%",textAlign:"right"}}
                                        type="text"
                                        placeholder={"1"}
                                        onChange={e => setUnitQuantity(e.target.unitQuantity)} value={unitQuantity} />
                                </div>
                                <div style={{width:130,textAlign:"center"}}>
                                    <div style={{textAlign:"right",marginRight:10,fontWeight:700,color:"#000",fontSize:18}}>23.00</div>
                                </div>
                            </div>
                        </div> :
                        <div className="lineBox">
                            <div className="invItem">Item 1</div>
                            <div className="textInputBox">
                                <input
                                    className="textInputText"
                                    style={{border:"none",width:"100%"}}
                                    type="text"
                                    placeholder={"Description"}
                                    onChange={e => setDescription(e.target.description)} value={description} />
                            </div>
                            <div className="textInputBox">
                                <div className="textInputText" style={{flex:1}}>Unit Price</div>
                                <div className="textInputText">$</div>
                                <input
                                    className="textInputText"
                                    style={{border:"none",textAlign:"right",width:120}}
                                    type="number"
                                    placeholder={"0.00"}
                                    onChange={e => setUnitPrice(e.target.unitPrice)} value={unitPrice} />
                            </div>
                            <div className="textInputBox">
                                <div className="textInputText" style={{flex:1}}>Quantity</div>
                                <input
                                    className="textInputText unitQuantity"
                                    style={{border:"none",textAlign:"right"}}
                                    type="number"
                                    placeholder={"1"}
                                    onChange={e => setUnitQuantity(e.target.unitQuantity)} value={unitQuantity} />
                            </div>
                            <div className="amountBox">
                                <div className="textInputText" style={{flex:1,textAlign:"right",marginRight:36}}>Amount</div>
                                <div className="textInputText">$</div>
                                <div className="textInputText" style={{width:120,textAlign:"right"}}>23.00</div>
                            </div>
                        </div>
                }

                <div
                    onClick={(e)=>setServices(
                        [...services,{
                            name: '',
                            quantity: 1,
                            price: {def: ''},
                            _type: 'basic'
                        }]
                    )}
                    className="lineButton">
                    + Add Item
                </div>
                <div className="paymentTotal">
                    <div>
                        <div className="textInputText" style={{marginLeft:16,fontSize:20}}>Payment Terms / Notes</div>
                        {
                            !out  &&
                            <div
                                onClick={()=>setOut(true)}
                                className="paymentBox">
                                <div>Account Holder: John Smith</div>
                                <div>Sort Code: 12-34-56</div>
                                <div>Account Number: 12345678</div>
                                <div>Bank Name: City Bank</div>
                            </div>
                        }

                        {
                            out &&
                            <div style={{margin:16}}>
                             <textarea
                                 placeholder={" Account holder:\r\n sort code: \r\n\ account number: \r\n bank: "}
                                 rows={4}
                                 className="textAreaText3"
                                 type="text" onChange={e => setNotes(e.target.from)} value={notes} />
                            </div>

                        }
                    </div>

                    <div style={{flex:1,marginRight:16}}>
                        <div className="amountBox">
                            <div className="textInputText" style={{flex: 1, textAlign: "right", marginRight: 36}}>Subtotal</div>
                            <div className="textInputText">$</div>
                            <div className="textInputText" style={{width: 120, textAlign: "right"}}>23.00</div>
                        </div>
                        {
                            !showTax &&
                            <div onClick={() => setShowTax(true)} className="expandText">
                                + Tax
                            </div>
                        }


                        {
                            showTax &&
                            <div className="amountBox">
                                <div onClick={() => setShowTax(false)} className="textInputText"
                                     style={{flex: 1, textAlign: "left", cursor: "pointer"}}>X
                                </div>
                                <div
                                    onClick={() => setTaxSelector(false)}
                                    className="textInputText"
                                    style={{flex: 1, textAlign: "right", marginRight: 36}}>Percentage
                                </div>
                                <div className="textInputText" style={{textAlign: "right", marginRight: 16}}>Tax</div>
                                <div className="textInputText">$</div>
                                <input placeholder={"0.00"} className="smallTextBox"
                                       type="text" onChange={e => setTax(e.target.tax)} value={tax}/>
                            </div>
                        }

                        <div className="amountBox">
                            <div className="textInputText" style={{flex: 1, textAlign: "right", marginRight: 36}}>Total</div>
                            <div className="textInputText">$</div>
                            <div className="textInputText" style={{width: 120, textAlign: "right"}}>23.00</div>
                        </div>
                        {
                            !priceAmount ?
                                <div className="expandText" onClick={() => setPriceAmount(true)}>
                                    + Amount Paid
                                </div> :
                                <div>
                                    <div className="amountBox">
                                        <div onClick={() => setPriceAmount(false)} className="textInputText"
                                             style={{flex: 1, textAlign: "left", cursor: "pointer"}}>X
                                        </div>
                                        <div className="textInputText"
                                             style={{flex: 1, textAlign: "right", marginRight: 36}}>Amount Paid
                                        </div>
                                        <div className="textInputText">$</div>
                                        <input placeholder={"0.00"} className="smallTextBox"
                                               type="text" onChange={e => setAmountPaid(e.target.amountPaid)}
                                               value={amountPaid}/>
                                    </div>

                                    <div className="amountBox">
                                        <div className="textInputText"
                                             style={{flex: 1, textAlign: "right", marginRight: 36}}>Amount Due
                                        </div>
                                        <div className="textInputText">$</div>
                                        <div className="smallTextBox">
                                            0.00
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>


            </div>
            <div className="bottomFixed">
                <div className="bottomButtons" style={{backgroundColor:"#445CC4"}}>DOWNLOAD</div>
                <div className="bottomButtons" style={{backgroundColor:"#1EAF94"}}>SEND</div>
            </div>

            <Modal  centered show={currency} onHide={()=>setCurrency(false)}>
                <div className="modalHead" onClick={()=>setCurrency(false)}>
                    Done
                </div>

                <div>
                    {
                        currencyList.map((item, idx) => (
                            <div
                                onClick={()=>setSelectCurrency(item.val)}
                                className="setCurrency"
                                style={{backgroundColor:selectCurrency == item.idx? "grey" : "#fff"}}
                            >
                                <div className="curModalLeft">
                                    {item.label}
                                </div>
                                <div className="curModalRight">
                                    {item.val}
                                </div>

                            </div>
                        ))
                    }
                </div>
                <Modal.Footer>
                    <input
                        className="searchInputBox"
                        style={{border:"none",width:"100%"}}
                        type="number"
                        placeholder={"Search Currency"}
                        onChange={e => setSearchCurrency(e.target.searchCurrency)} value={searchCurrency}
                    />
                    {/*<Button variant="secondary" onClick={()=>setCurrency(false)}>*/}
                    {/*    Close*/}
                    {/*</Button>*/}
                    {/*<Button variant="primary" onClick={()=>setCurrency(false)}>*/}
                    {/*    Save Changes*/}
                    {/*</Button>*/}
                </Modal.Footer>
            </Modal>
            <Modal  centered show={taxSelector} onHide={()=>setTaxSelector(false)}>
                <div className="modalHead" onClick={()=>setTaxSelector(false)}>
                    Done
                </div>

                <div>
                    {
                        currencyList.map((item, idx) => (
                            <div
                                onClick={()=>setSelectCurrency(item.val)}
                                className="setCurrency"
                            >
                                <div className="curModalLeft">
                                    {item.label}
                                </div>
                                <div className="curModalRight">
                                    {item.val}
                                </div>

                            </div>
                        ))
                    }
                </div>
                <Modal.Footer>
                    <input
                        className="searchInputBox"
                        style={{border:"none",width:"100%"}}
                        type="number"
                        placeholder={"Search Currency"}
                        onChange={e => setSearchCurrency(e.target.searchCurrency)} value={searchCurrency}
                    />
                    {/*<Button variant="secondary" onClick={()=>setCurrency(false)}>*/}
                    {/*    Close*/}
                    {/*</Button>*/}
                    {/*<Button variant="primary" onClick={()=>setCurrency(false)}>*/}
                    {/*    Save Changes*/}
                    {/*</Button>*/}
                </Modal.Footer>
            </Modal>
        </div>
    );
    // const [invDate, onChange] = useState(new Date());

    // return (
    //     <div>
    //         <Hdr invGen={true}/>
    //         {/*<div className="invGen">*/}
    //         {/*    <div className="topHead">*/}
    //         {/*        <div style={{flex:1}}>*/}
    //         {/*            <div>*/}
    //         {/*                add yor logo*/}
    //         {/*            </div>*/}
    //         {/*        </div>*/}
    //         {/*        <div className="region">*/}
    //         {/*            <div>*/}
    //         {/*                usd*/}
    //         {/*            </div>*/}
    //         {/*            <div>*/}
    //         {/*                Invoice*/}
    //         {/*            </div>*/}
    //         {/*        </div>*/}
    //         {/*    </div>*/}
    //         {/*    <div>*/}
    //         {/*        <div className="inputBox">*/}
    //         {/*            <div className="inputLabel">*Invoice Number:</div>*/}
    //         {/*            <div className="inputValue">*/}
    //         {/*                <input className="inputText" type="text" value={this.state.invoiceNumber}/>*/}
    //         {/*            </div>*/}
    //         {/*        </div>*/}
    //
    //         {/*        <div className="inputBox">*/}
    //         {/*            <div className="inputLabel">*Invoice Date:</div>*/}
    //         {/*            <div className="inputValue">*/}
    //         {/*                <Calendar className="inputText" onChange={()=>this.setState({})} showWeekNumbers value={this.state.invoiceDate} />*/}
    //         {/*                /!*<input className="inputText" type="text" value={this.state.invoiceNumber}/>*!/*/}
    //         {/*            </div>*/}
    //         {/*        </div>*/}
    //
    //         {/*        <div className="inputBox">*/}
    //         {/*            <div className="inputLabel">Due Date</div>*/}
    //         {/*            <div className="inputValue">*/}
    //         {/*                <input className="inputText" type="text" value={this.state.dueDate}/>*/}
    //         {/*            </div>*/}
    //         {/*        </div>*/}
    //         {/*    </div>*/}
    //         {/*</div>*/}
    //         <Ftr/>
    //     </div>
    // );
}

