/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'
import {Image} from "react-bootstrap";

/**
 */
class P404 extends React.PureComponent
{
    // constructor( props )
    // {
    //     super( props )
    // }

    render()
    {
        return (
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",flex:1,flexDirection:"column", marginTop:32}}>
                <Image alt="error page" src={require('./images/404.png')}/>

                <div style={{fontSize:24,textAlign:"center",marginTop:16,fontWeight:700}}>Page Not Found</div>
            </div>
            )
    }
}   // P404

/**
 */
export default P404
