/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'
import Hdr from '../_common/hdr'
import Ftr from '../_common/ftr'
import {connect} from 'react-redux'
import './download.css'
import * as actions from '../../rdx/actions'
import{Container, Image, Button,iframe} from 'react-bootstrap'
import { navigate } from "@reach/router"
import ScrollAnimation from 'react-animate-on-scroll';
/**
 */
class Download_Home extends React.PureComponent
{
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render()
    {
        return (
            <div style={{flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: "column"}}>
                <Hdr download={true}/>
                <div className="downloadBg">
                    <div className="downloadHead">
                        <div>
                            <Image alt="invoice generator creation page" className="bottomImage downloadImage" src={require('../assets/InvoiceCreateDLD.png')}/>
                        </div>
                        <div>
                            <div className="common">
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.histreet.business">
                                    <Image alt="invoice generator play store" className="gap" src={require('../home/images/icon/playstore.png')}/>
                                </a>
                                <a target="_blank" href="https://apps.apple.com/gb/app/histreet-business/id1494624906">
                                    <Image alt="invoice generator app store" className="gap" src={require('../home/images/icon/appstore.png')}/>
                                </a>
                            </div>
                        </div>
                    </div>
                    {/*<Image className="bottomImage" src={require('../assets/hsbd.jpg')}/>*/}
                </div>
                <Ftr/>
            </div>
        )
    }

    /**
     */
    componentDidMount()
    {
    }
}   // class Eye_Home

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)(Download_Home )


